import { availableLocales } from './i18n'

export default defineI18nConfig(() => {
  return {
    availableLocales: availableLocales.map(l => l.code),
    fallbackLocale: 'de-DE',
    fallbackWarn: true,
    legacy: false,
    missingWarn: true,
  }
})
